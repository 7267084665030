import {env} from '@ui/env'

export class ApiService {

  constructor(
    protected uri: string,
  ) {
  }

  protected getUrl(branch?: string | number): string {
    let url = `${env.apiHost}/api/v1/${this.uri}`

    if (branch) {
      url += `/${branch}`
    }

    return `${url}/`
  }
}
